.formContainer {
  display: grid;
  margin-top: 45px;
  grid-template-columns: 1fr 4fr;
  gap: 30px;
}

.formNavSection {
  background-color: var(--branded-darker-gray);
  color: var(--branded-light-gray);
  border-radius: 5px;
  padding: 30px 10px;
}

.form {
  padding: 0;
}

.formTitle {
  margin: 0 0 15px 0;
}

.formDesc {
  font-size: medium;
  margin-bottom: 30px;
}

.blockWrapper {
  display: flex;
  flex-direction: column;
}

.block {
  position: relative;
  background-color: white;
  padding: 20px 30px;
  border-radius: 5px;
}

.pageSummary {
  font-size: small;
  text-align: center;
  margin-bottom: 30px;
}

.toogleAll {
  text-align: right;
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  cursor: pointer;
  font-weight: bold;
  font-size: 0.8em;
  color: var(--blue);
}

.toogle {
  font-size: 2em;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.fieldsWrapper {
  margin: 15px 0 15px 0;
  display: flex;
  flex-direction: column;
}

.fieldsWrapperCollapse {
  margin: 0;
  height: 0;
}

.blockTitle {
  margin: 0;
}

.hidden {
  display: none;
}

.field {
  color: black;
  font-size: medium;
  margin-bottom: 15px;
}

.skeletonTitle {
  margin-bottom: 15px;
}

.skeletonLabel {
  margin-bottom: 5px;
}

.label {
  display: block;
}

.errFieldMsg {
  font-size: small;
  color: var(--branded-error-red);
}

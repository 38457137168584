.taskGridContainer {
    display: grid;
    grid-template-areas:
      'Title Title Title MarkDone'
      'Due Due Due Due'
      'Notes Notes Notes Notes'
      'Action Action Action2 Action2'; 
    width:100%;
    border-style: solid;
    border-width: 1px;
    border-color: lightgray;
    padding: 1%;
    margin-bottom: 10px;
}

.taskGridItemTitle{
    grid-area: Title;
    margin-bottom: 5px;
}

.taskGridDescription {
    font-weight: bold;
}

.taskGridItemDone{
    grid-area: MarkDone;
}

.taskGridItemDue{
    grid-area: Due;
    margin-bottom: 10px;
}

.taskGridItemNotes{
    grid-area: Notes;
}

.taskActionButton{
    width: 95%;
    margin-top: 10px;
    align-items: center;
    display:table-cell;
    vertical-align:middle
}

.taskAction2Button{
    width: 95%;
    margin-top: 10px;
    float: right;
    align-items: center;
    display:table-cell;
    vertical-align:middle
}

.taskGridContainer.taskGridOverDue {
    border-color: red;
}

.taskGridItemAction1{
    grid-area: Action;
    margin-bottom: 10px;
}

.taskGridItemAction2{
    grid-area: Action2;
    margin-bottom: 10px;
}
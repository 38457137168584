.mainGridContainer {
    display: grid;
    grid-template-areas:
      'Counters Counters Counters Counters Counters Counters'
      'NewNotifications NewNotifications NewTasks NewTasks NewParticipants NewParticipants'
      'TasksToday TasksToday TasksToday AppointmentToday AppointmentToday AppointmentToday'; 
    width:100%;
    height:100%; 
    grid-template-rows: 15% 30% auto;
    grid-template-columns: 16% 16% 16% 16% 16% 16%;
    grid-gap: 10px;
    border-color: lightgray;
    background: #E7E7E7;
}

.gridItemCounters{
    grid-area: Counters;
    padding-top: 5px;
}

.gridItemNewNotifications{
    grid-area: NewNotifications;

}

.gridItemNewTasks{
    grid-area: NewTasks;
}

.gridItemNewParticipants{
    grid-area: NewParticipants;
}

.gridItemTasksToday{
    grid-area: TasksToday;
}

.gridItemAppointmentToday{
    grid-area: AppointmentToday;
}


.counterGridContainer {
    display: grid;
    grid-template-areas:
      'Documents Referrals Tasks ParticipantCaseLoad';
    width:100%;
    height:100%; 
    border-color: lightgray;
}

.counterGridItemDocuments{
    grid-area: Documents;
}



.CounterGridItemReferrals{
    grid-area: Referrals;
}


.CounterGridItemTasks{
    grid-area: Tasks;
}

.CounterGridItemParticipantCaseLoad{
    grid-area: ParticipantCaseLoad;
}

.CounterGridItem{
    display: flex;
    justify-content: center;
    align-items: center;
}

.counterPanel{
    background-color: white;
    width: 200px;
    height: 80%;
    position: relative;
    
}

.counterPanelName{
    position: absolute;
    bottom: 1px;
    left: 5px;
    font-weight: 600;
}

.counterPanelIcon{
    position: absolute;
    top: -10px;
    left: 5px;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 8px 8px 5px lightgrey;
}

.counterPanelValue{
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: x-large;
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.counterButton{
    width: 22px;
    height: 22px;
    position: absolute;
    right: 5px;
    bottom: 5px;
    padding-left: 0px;
    padding-top: 0;
}

.summaryPanel{
    width: 100%;
    height: 100%;
    position: relative;
}

.summaryHeader{
    width: 80%;
    height: 30px;
    align-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 10%;
    border-radius: 10px;
    box-shadow: 8px 8px 5px lightgrey;
}

.summaryHeaderButton{
    width: 22px;
    height: 22px;
    position: absolute;
    right: 15px;
    padding-left: 0px;
    padding-top: 0;
}

.summaryDetail{
    background-color: white;
    width: 95%;
    bottom: 10px;
    left: 2.5%;
    height: 90%;
    position: absolute;  
    padding-top: 20px; 
}

.todayPanel{
    width: 100%;
    height: 100%;
    position: relative;
}

.todayHeader{
    width: 80%;
    height: 60px;
    align-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 10%;
    border-radius: 10px;
    box-shadow: 8px 8px 5px lightgrey;
}

.todayDetail{
    background-color: white;
    width: 98%;
    bottom: 0px;
    left: 1%;
    height: 90%;
    position: absolute;   
    padding-top: 20px;
}

.detailHeaderButton{
    width: 25px;
    height: 25px;
    position: absolute;
    right: 15px;
    padding-left: 0px;
    padding-top: 0;
}
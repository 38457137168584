.formContainer {
  position: relative;
  display: grid;
  margin: 45px 5% 0 0;
  grid-template-columns: 220px 1fr;
  gap: 30px;
}
.stickyWrapper {
  position: sticky;
  display: grid;
  grid-template-rows: 50vh auto;
  top: 15px;
  gap: 30px;
}

.formNavSection {
  background-color: #22272ae0;
  color: white;
  font-size: 0.875em;
  line-height: 1.3;
  border-radius: 5px;
  padding: 30px 10px;
}

.form {
  padding: 0;
}

.formTitle {
  margin: 0 0 15px 0;
}

.formDesc {
  font-size: medium;
  margin-bottom: 30px;
}

.blockWrapper {
  display: flex;
  flex-direction: column;
}

.block {
  position: relative;
  background-color: white;
  padding: 20px 30px;
  border-radius: 5px;
}

.pageSummary {
  font-size: small;
  text-align: center;
  margin-bottom: 30px;
}

.toogleAll {
  text-align: right;
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  cursor: pointer;
  font-weight: bold;
  font-size: 0.8em;
  color: var(--blue);
}

.toogle {
  font-size: 2em;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.fieldsWrapper {
  margin: 15px 0 15px 0;
  display: flex;
  flex-direction: column;
}

.fieldsWrapperCollapse {
  margin: 0;
  height: 0;
}

.blockTitle {
  margin: 0;
}

.hidden {
  display: none;
}

.field {
  color: black;
  font-size: medium;
  margin-bottom: 15px;
}

.field textarea {
  width: 75%;
}

.skeletonTitle {
  margin-bottom: 15px;
}

.skeletonLabel {
  margin-bottom: 5px;
}

.label {
  display: block;
  font-size: 1em;
  font-weight: bold;
  font-size: medium;
}

.description {
  font-size: 0.875em;
  margin-bottom: 5px;
  font-weight: 300;
}

.errFieldMsg {
  font-size: small;
  color: var(--branded-error-red);
}

.btnBack {
  display: inline-flex;
  cursor: pointer;
  color: white;
  font-size: 1rem;
  padding: 8px 20px 11px;
  border: none;
  border-radius: 4px;
  background-color: var(--branded-purple);
  margin: 5px;
}
.validFieldMsg {
  font-size: small;
  color: green;
}
.asterisk {
  color: red;
}
.muteNotSelected {
    background: white;
    color: black ;
    border-radius: 4px;
    border: 2px solid var(--branded-purple);
}

.NavButton{
    cursor: pointer;
    background: none;
    border: 0px;
    color: white;
}

.addSpace{
    margin-bottom: 10px;
}

.addLargeSpace{
    margin-bottom: 30px;
}

.NoteMarkRead {
    float: right;
}

.noteAccordion{
    width: 100%;
    margin-bottom: 10px;
}

/*Notifications acordian grid: */
.noteHeaderGridContainer {
    display: grid;
    grid-template-areas:
      'title createDate read'
      'descr org read';
    width:100%
  }

.noteHeaderGridItemTitle { 
    grid-area: title;
    font-weight: bold;
    font-size: larger;
    width: 580px;
}  

.noteHeaderGridItemCreation { 
    grid-area: createDate;
    text-align: right;
    font-size: small;
    width: fit-content;
    float: right
}  

.noteHeaderGridItemOrg { 
    grid-area: org;
    text-align: right;
    font-size: small;
    width: fit-content;
    float: right
}  

.noteHeaderGridItemDescr { 
    grid-area: descr;
}  

.noteHeaderGridItemRead { 
    grid-area: read;
    margin-left: 10px;
}  

/*Notifications acordian body grid: */
.noteBodyGridContainer {
    display: grid;
    grid-template-areas:
      'lastMod read'
      'action1 action2';
    width:100%
  }

.noteBodyGridItemMod{
    grid-area: lastMod;
}

.noteBodyGridItemRead{
    grid-area: read;
}

.noteBodyGridItemAct1{
    grid-area: action1;
}

.noteBodyGridItemAct2{
    grid-area: action2;
}

.noteBodyLabel{
    font-weight: bold;
}

.noteBodyActionButton{
    width: 380px;
    height: 80px;
    margin-top: 10px;
    align-items: center;
    display:table-cell;
    vertical-align:middle
}

/*Notifications acordian Filters grid: */
.noteFilterGridContainer {
    display: grid;
    grid-template-areas:
      'titleSearch titleSearch eventType eventType'
      'searchBox searchBox eventTypeDD eventTypeDD'
      'orgSearch orgSearch sort sort'
      'orgDD orgDD sortDD sortDD'
      'created created read read'
      'createdFrom createdTo readFrom readTo';
    width:100%
  }

 .noteFilterGridItemTitleSearch{
    grid-area: titleSearch;
}

.noteFilterGridItemEventType{
    grid-area: eventType;
    
}

.noteFilterGridItemSort{
    grid-area: sort;  
}

.noteFilterGridItemOrg{
    grid-area: orgSearch; 
}
.noteFilterGridItemSearchBox{
    grid-area: searchBox;
}

.noteFilterGridItemEventTypeDD{
    grid-area: eventTypeDD;
}

.noteFilterGridItemSortDD{
    grid-area: sortDD;
}

.noteFilterGridItemOrgDD{
    grid-area: orgDD;
}

.noteFilterGridItemCreated{
    grid-area: created;
}

.noteFilterGridItemRead{
    grid-area: read;
}

.noteFilterGridItemCreatedFrom{
    grid-area: createdFrom;
}

.noteFilterGridItemCreatedTo{
    grid-area: createdTo;
}

.noteFilterGridItemReadFrom{
    grid-area: readFrom;
}

.noteFilterGridItemReadTo{
    grid-area: readTo;
}

.noteFilterTextboxWidth{
    width: 380px;
}

.noteFilterDropdownWidth{
    width: 185;
}

.noteFilterCalender{
    width: 185px;
    
}

/*Notifications acordian grid: */
.noteFilterHeaderGridContainer {
    display: grid;
    grid-template-areas: 'title button';
    width:100%
  }

.noteFilterHeader{
grid-area: title;
text-align: left;
position:absolute; 
top:35%;
}  

.noteFilterButton{
grid-area: button;
}    

.noteMainTabHeader{
    margin-right: 10px;
    height: 45px;
    width: 120px;
    align-items: center;
    text-align: center;
    vertical-align: middle;
    
}

.noteMainTabHeaderActive{
    border-color: var(--branded-purple);
}

.noteHighImportant{
    color: red;
    font-size: 18px;
}

.noteSettingsLayout {
    display: grid;
    grid-template-areas:
        'body'
        'button';
    grid-template-rows: auto 40px;
    width:100%;
    height: 100%;
    min-height: calc(650px);
  }

  .noteSettingsLayoutFilter{
    grid-area: filter;
  } 

  .noteSettingsLayoutBody{
    grid-area: body;
  } 

  .noteSettingsLayoutButton{
    grid-area: button;
    padding-top: 15px;
  } 

  .noteSettingsTable{
    height: 100%;
  }

  .noteSettingsTableHeader{
    display: flex;
  }

  .noteSettingsCheckedColumn{
    max-width: 150px;
  }
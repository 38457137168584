.devToolsTopIcon{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5px;
    background-color: var(--branded-action-purple);
    padding-left: 5px;
    padding-right: 7px;
    border-radius: 8px;
}

.hasActiveTool{
  background-color: white;
  width: 13px;
  height: 13px;
  border-radius: 13px;
  margin-top: 6px;
}

.devToolsModal{
    min-width: 600px;
    width: 50vw;
    height: 900px;
}

.devToolsModalHeader{
  color: var(--branded-action-purple);
  display: flex;
  align-items: center;
  cursor: pointer;
  min-height: 42px;
  width: min-content;
  padding-left: 10px;
  padding-right: 10px;
  border: white 1px solid;
  border-radius: 10px;
}

.devToolsModalHeader:hover{
  background-color: #f3eff8;
  border-color: var(--branded-action-purple);
}

.devToolsModalHeaderIcon{
  margin-right: 10px;
}

.devToolsModalHeaderDisable{
  background-color: #ED4337;
  border-color: #ED4337;
}



.mainBodyLayout{
    display: grid;
    grid-template-areas: 
        "title blank env"
        "body body body";
    grid-template-columns: 250px auto 150px;
    grid-template-rows: 70px calc(100% - 70px);
    width: 100%;
    max-height: 100%;
    
}

.mainBodyLayoutTitle {
    grid-area: title;
    font-weight: 700;
    font-size: 24px;
    display: flex;
    align-items: center;
  }

  .mainBodyLayoutBlank {
    grid-area: blank;
  }

  .mainBodyLayoutEnv {
    grid-area: env;
    display: flex;
    flex-direction: column;
    text-align: right;
    background-color: #F3EFF8;
    padding: 10px;
    font-size: 12px;
  }

  .mainBodyLayoutBody {
    grid-area: body;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding-top: 10px;
  }

  .envName{
    font-weight: 700;
    font-size: 20px;
    text-transform: capitalize;
  }

  .cardLayout{
    display: grid;
    grid-template-areas: 
        "title status"
        "description status"
        "body body"
        "footer footer";
    grid-template-columns: auto 80px;
    grid-template-rows: 30px 40px auto 50px;
    width: 49%;
    height: 110px;
    border: solid #E7E0F1 1px   ;
    overflow: hidden;
    padding: 24px 16px;
    cursor: pointer;
  }

  .cardLayout:hover{
    background-color: #f3eff8;
    border-color: var(--branded-action-purple);
  }

  .cardLayout:active{
    border-color: blue;
  }

  .cardLayoutExpanded{
    height: 100%;
    width: 100%;
    cursor: default;
  }

  .cardLayoutExpanded:hover{
    background-color: white;
    border-color: #E7E0F1;
  }

  .cardLayoutHidden{
    display: none;
  }

  .cardLayoutTitle {
    grid-area: title;
    font-weight: 700;
    size: 14px;
  }
  
  .cardLayoutStatus {
    grid-area: status;
    display: flex;
    justify-content: flex-end;
  }
  
  .cardLayoutDescription {
    grid-area: description;
  }
  
  .cardLayoutBody {
    grid-area: body;
    display: none;
  }

  .cardLayoutFooter{
    grid-area: footer;
    display: none;
  }

  .cardLayoutExpanded .cardLayoutBody {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    overflow: auto;
  } 

  .cardLayoutExpanded .cardLayoutFooter {
    padding-top: 20px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  } 

  .button{
    height: 40px;
    border-radius: 28px;
  }

  .noBackground{
    background: transparent;
    color: var(--branded-action-purple);
  }

  .activeBadge{
    width: 16px;
    height: 16px;
    border-radius: 16px;
    background-color: var(--branded-action-purple);
  }

  .expandedBadge{
    width: 75px;
    height: 25px;
    font-weight: 600;
    color: white;
    text-align: center;
  }
.serviceLayout{
    display: grid;
    grid-template-areas: 
        "name name"
        "url reset";
    grid-template-columns: auto 50px;
    width: 100%;    
    height: 80px;
    gap: 10px;
    padding-right: 10px;
    margin-top: 15px;
}

.mainBodyLayoutName {
    grid-area: name;
    font-weight: 700;
  }

  .mainBodyLayoutUrl {
    grid-area: url;
  }

  .mainBodyLayoutReset {
    grid-area: reset;
  }

  .urlInput{
    width: 100%;
  }

  .resetButton{
    width: 100%;
    background: transparent;
    color: var(--branded-action-purple);
    display: flex;
    align-content: center;
  }

  .overriddenServiceTag{
    background-color: #FCE0AA;
    color: #C1850E;
    padding: 5px;
    border-radius: 6px;
  }
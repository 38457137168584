.buttons {
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-bottom: 15px;
  }

  .checkBox{
    background: var(--branded-purple);
}

.footer{
  bottom: 0px;
  z-index: 2;
  background-color: var(--branded-gray);
  width: 100%;
  float: right;
}

.participantfooter{
  bottom: 0px;
  z-index: 2;
  background-color: white;
  width: 100%;
  float: right;
}

.participantfooter .buttonContainer{
  text-align: right;
  display: inline-block;
  width: 20%;
}

.footer .buttonContainer{
  text-align: right;
  display: inline-block;
  width: 20%;
}

.footerNoBg{
  bottom: 0px;
  z-index: 2;
  width: 100%;
  float: right;
}

.footerNoBg .buttonContainerFull{
  text-align: right;
  display: inline-block;
}

.button{
  background: var(--branded-purple);
}
.participantMenu {
  margin: 10px 0 30px;
  padding: 15px 30px;
  background: #ebebeb;
  border-radius: 5px;
}

.tieredMenu {
  margin-top: 10px;
  background: #f4f4f4;
  width: 100%;
}

.participantMenu ul {
  margin: 0;
}

hr {
  margin: 20px 0 30px;
  height: 1px;
  background: none;
  border: none;
  border-bottom: 1px solid var(--branded-light-gray);
}

.parentVertical{
  display: inline-block;
  width: 25%;
  float: left;
}

.childVertical{
  width: 76%;
  padding-top: 21px;
}

.clearFix{
  overflow: hidden;
  width: 100%;
}
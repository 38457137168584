.nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 0;
  margin: 0;
  gap: 15px;
  background: rgba(255, 255, 255, 0.05);
  overflow-y: scroll;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.nav li {
  display: grid;
  grid-template-columns: 12px auto;
  padding: 4px 16px 4px 8px;
  gap: 8px;
  width: 100%;
  align-items: center;
}

.active {
  position: relative;
  background: var(--branded-darker-gray);
  color: white;
  display: flex;
}

.nav li svg {
  font-size: 12px;
}

.edit svg {
  color: var(--branded-yellow);
}

.valid svg {
  color: var(--branded-validation-green);
}

.nav button {
  color: inherit;
  text-align: left;
  display: block;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  line-height: 1.2;
}

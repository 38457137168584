.multiBlock {
  background-color: var(--branded-light-gray);
  border-radius: 5px;
  padding: 15px 15px 30px;
  margin-bottom: 30px;
}

.multiBlock svg {
  font-size: 1.4rem;
}

.head {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 5px;
  color: var(--branded-dark-gray);
}
.head h3 {
  margin: 0;
}

.blocks {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

.block {
  background-color: white;
  border-radius: 5px;
  padding: 10px;
}

.actions {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px dotted var(--branded-darker-gray);
  display: flex;
  justify-content: space-between;
}

.taskGridDescription {
    font-weight: bold;
}

.noPadding{
    padding:0;
}

.noPadding.p-tabview-panels{
    padding:0;
}

.taskGridContainer.taskGridOverDue {
    border-color: red;
}

.taskGridContainer {
    display: grid;
    grid-template-areas:
    'Title Title Created editBtn'
    ' AssignedBy AssignedBy Due editBtn'
    'Action Action Action2 Action2'
    'Notes Notes Notes Notes';
    grid-template-columns: 60% auto auto auto;  
    width:100%;
    border-style: solid;
    border-width: 1px;
    border-color: lightgray;
    padding: 1%;
    margin-bottom: 10px;
}

.taskGridContainerCompleted {
    display: grid;
    grid-template-areas:
      'Title Created'
      'Due Done'
      'AssignedBy AssignedBy'
      'Action Action2'
      'Notes Notes';
    width:100%;
    border-style: solid;
    border-width: 1px;
    border-color: lightgray;
    padding: 1%;
    margin-bottom: 10px;
}

 .taskGridItemTitle{
    grid-area: Title;
    margin-bottom: 5px;

}


.taskGridItemDone{
    grid-area: Done;
    margin-bottom: 5px;
}

.taskGridItemCreated{
    grid-area: Created;
    margin-bottom: 5px;
}

.taskGridItemAssignedBy{
    grid-area: AssignedBy;
    margin-bottom: 10px;
    width: 100%;
}

.taskGridItemDue{
    grid-area: Due;
    margin-bottom: 10px;
}

.taskGridItemAction1{
    grid-area: Action;
    margin-bottom: 10px;
}

.taskGridItemAction2{
    grid-area: Action2;
    margin-bottom: 10px;
}

.taskGridItemNotes{
    grid-area: Notes;
}

.taskGridItemFunction{
    grid-area: editBtn;
}

.taskActionButton{
    width: 380px;
    height: 80px;
    margin-top: 10px;
    align-items: center;
    display:table-cell;
    vertical-align:middle
}
/*Notifications acordian grid: */
.filterHeaderGridContainer {
  display: grid;
  grid-template-areas: "title button";
  width: 100%;
}

.filterHeaderGridTitle {
  grid-area: title;
  text-align: left;
  position: absolute;
  top: 35%;
}

.filterHeaderGridButtons {
  grid-area: button;
}

.filterButtonContainer{
  float: right;
  border-style: solid;
  border-width: 1px;
  margin-left: 5px;
  border-radius: 10px;
  border-color: gray;
}

.filterHeaderButton {
  float: right;
  width: 40px;
  height: 33px;
  display: flex;
  padding: 4px 9px;
  margin: 5px
}

.filterButtons {
  text-align: right;
}

.filterButton {
  margin-left: 10px;
}

.label {
  font-weight: bold;
}

.dateInput {
  margin-bottom: 10px;
}

.helpText {
  font-size: small;
}

.searchText {
  width: 100%;
}
.status {
  width: 40%;
  margin-bottom: 1rem;
}

.layoutButtonSelected {
  background-color: white;
  color: var(--branded-purple);
}

.docSignHeader {
  margin-bottom: 1rem;
  margin-right: 1rem;
  background-color: var(--branded-purple);
}
.docTemplateInfo_p {
  font-size: larger;
  margin-bottom: 1rem;
}

.documentsListInfo {
  list-style-type: disc;
  margin-left: 2rem;
  margin-bottom: 1rem;
}
/*Notifications acordian grid: */
.filterHeaderGridContainer {
  display: grid;
  grid-template-areas: "title button";
  width: 100%;
}

.filterHeaderGridTitle {
  grid-area: title;
  text-align: left;
  position: absolute;
  top: 35%;
}

.filterHeaderGridButtons {
  grid-area: button;
}

.filterButtons {
  text-align: right;
}

.filterButton {
  margin-left: 10px;
}

.filterbuttonHighlight{
  background-color: white;
  color: var(--branded-purple);
}

.label {
  font-weight: bold;
}

.dateInput {
  margin-bottom: 10px;
}

.helpText {
  font-size: small;
}

.searchText {
  width: 100%;
}
.status {
  width: 40%;
  margin-bottom: 1rem;
}

.layoutButtonUnselected {
  background-color: darkgray;
}

.filterbuttonSpaceRight{
  margin-right: 20px;
}

.sortDirection{
  width: 25px;
  margin-left: 5px;
  padding: 8px 1px;
}
.mainLayout{
    display: grid;
    grid-template-areas:
    'header action'  
    'body body'
    'error error';
    width:100%;
    row-gap: 10px;
    height: 100%;
}

.mainLayoutWithSession{
    display: grid;
    grid-template-areas:
    'task task'
    'session session'
    'header action'  
    'body body'
    'error error';
    width:100%;
    row-gap: 10px;
    height: 100%;
}

.mainLayoutHeader{
    grid-area: header;
}

.mainLayoutAction{
    grid-area: action;
    display: flex;
    flex-direction: row-reverse;
}

.mainLayoutBody{
    grid-area: body;
}

.mainLayoutTask{
    grid-area: task;
}

.mainLayoutSession{
    grid-area: session;
}

.mainLayoutError{
    grid-area: error;
}

.mainActionButton{
    height: fit-content;
}

.mainHeaderError{
    color: red;
    font-size: medium;
}

.columnRequired{
    width: 100%;
    color: var(--branded-purple);
    margin-left: 20px;
}

.addAssessmentDialog{
    max-width: 1000px;
    min-width: 500px;
}

.addAssessmentLayout{
    display: grid;
    grid-template-areas:
    'search'  
    'body'
    'button';
    width:100%;
    row-gap: 10px;
    height: 100%;
}

.addAssessmentLayoutSearch{
    grid-area: search;
}

.addAssessmentLayoutBody{
    grid-area: body;
}

.addAssessmentLayoutButton{
    grid-area: button;
    width: 100%;
}

.addAssessmentSearch{
    width: 100%;
}

.hideTableHeader :global(.p-datatable-thead){
    display: none;
}

.addAssessmentButton{
    float: right;
    margin-right: 0px;
}

.blockActionButtons{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.blockActionButtons :global(.saveBtn){
    border-radius: 28px;
    padding: 8px 16px;
    font-weight: 600;
    font-size: 16px;
    margin-right: 10px;
    background-color: var(--branded-purple);
}

.blockActionButtons :global(.saveBtn:disabled){
    background-color: #cccccc;
    color: #666666;
    opacity: 0.65;
    border: 1px solid #883cae;
}

.blockActionButtons :global(.editBtn){
    border-radius: 28px;
    padding: 8px 16px;
    font-weight: 600;
    font-size: 16px;
    margin-right: 10px;
    background-color: var(--branded-purple);
    color: white;
}

.actionColumn{
    width: 140px;
}

.title{
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 2rem;
}

.sessionLayout{
    display: grid;
    grid-template-areas:
    'title title'  
    'date location';
    width:100%;
    gap: 10px;
    height: 100%;
    padding-right: 10px;
}

.sessionLayoutTitle{
    grid-area: title;
}

.sessionLayoutDate{
    grid-area: date;
}

.sessionLayoutLocation{
    grid-area: location;
}

.sessionDate{
    width: 100%;
    margin-top: 6px;
}

.sessionDropDown{
    width: 100%;
    margin-bottom: 10px;
}

.sessionDropdownContainer{
    display: flex;
}

.mainLayoutWithTask{
    display: grid;
    grid-template-areas:
    'title title'
    'name staff'
    'dueDate completedDate';  
    grid-template-columns: 50% 50%;
    width:100%;
    row-gap: 10px;
    height: 100%;
}

.mainLayoutWithTaskTitle{
    grid-area: title;
}


.mainLayoutWithTaskName{
    grid-area: name;
}

.mainLayoutWithTaskStaff{
    grid-area: staff;
}

.mainLayoutWithDue{
    grid-area: dueDate;
}

.mainLayoutWithTaskComplete{
    grid-area: completedDate;
}

.taskTitle{
    font-weight: 600;
}

.taskValue{
font-weight: 400;
font-size: 0.95rem;
}

.assessmentInformationPanel{
    background-color: #EEDEF6 ;
    border-color: #EEDEF6;
    margin-bottom: 10px;
    border-radius: 10px;
}

.assessmentInformationPanel :global(.p-panel-header){
    background-color: #EEDEF6 ;
    padding: 0px 5px !important;
}

.assessmentInformationPanel :global(.p-panel-content){
    padding: 1px;
    background-color: #EEDEF6 ;
    padding:  10px;
    border-radius: 10px; 
}

.assessmentInformation{
    display: flex;
    flex-direction: column;
    gap: 10px;

}
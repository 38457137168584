.defaultGridContainer {
    display: grid;
    grid-template-areas:
    'title'
    'organization'
    'description';
    grid-template-columns: auto; 
    width:100%;
    border-style: solid;
    border-width: 1px;
    border-color: lightgray;
    padding: 1%;
    margin-bottom: 10px;
}

.gridItemTitle{
    grid-area: title;
}

.gridItemOrganization{
    grid-area: organization;
}

.gridItemDescription{
    grid-area: description;
}

.notificationHeader {
    font-weight: bold;
}
.header {
  background-color: var(--branded-dark-gray);
}

.footer {
  margin: 30px 0 15px 0;
  font-size: 0.8rem;
  text-align: center;
}

.top {
  padding: 5px 0;
}

.wrapper {
  display: flex;
  justify-content: space-between;
}

.secondary {
  background-color: var(--branded-purple);
}


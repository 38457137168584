@import "./AssessmentDefaults.css";

.dialog{
    width: 900px;
    height: 900px;
}

.dialog :global(.p-dialog-content){
    padding-right: 0px;
}

.mainLayout{
    display: grid;
    grid-template-areas:
    'instruction'
    'outcomesHeader'  
    'outcomes'
    'formHeader'
    'form';
    grid-template-rows: minmax(0, min-content) min-content min-content min-content auto;
    width:100%;
    height: 100%;
}

.mainLayoutInstruction{
    grid-area: instruction;
    background: #F3EFF8;
    width: calc(100% - 10px);
    border-radius: 10px;
    padding: 10px 20px;
    white-space: pre-line;
    margin-bottom: 10px;
}

.mainLayoutOutcomesHeader{
    grid-area: outcomesHeader;
    font-weight: 700;
    margin-bottom: 5px;
}

.mainLayoutOutcomes{
    grid-area: outcomes;
}

.mainLayoutFormHeader{
    grid-area: formHeader;
    font-weight: 700;
    margin-bottom: 5px;
}

.mainLayoutForm{
    grid-area: form;
}

.form{
    height: 100%;
}

.formLayout{
    display: grid;
    grid-template-areas:
      'body'
      'footer';
    width:100%;
    height: 100%;
    grid-gap: 0px;
    padding-right: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
}

.formLayoutBody{
    grid-area: body;
    overflow: auto;
    padding: 10px;
    background: #F3EFF8;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

}

.formLayoutFooter{
    grid-area: footer;
    padding: 10px;
    background: #F3EFF8;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.buttonContainer{
    display: flex;
    flex-direction: row-reverse;
}

.outcomeOnlyLayout{
    display: grid;
    grid-template-areas:
    'instruction'
    'outcomes';
    grid-template-rows: minmax(0, min-content) auto;
    width:calc(100% - 10px);
    height: 100%;
}

.outcomeOnly{
    height: 100%;
}

.outcomeLayout{
    display: grid;
    grid-template-areas:
      'score'
      'outcomeHeader'
      'outcomes'
      'remarksHeader'
      'remarks'
      'footer';
    grid-template-rows: 70px 25px min-content 25px auto 50px;
    width:calc(100% - 10px);
    height: 100%;
    grid-gap: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 20px;
    border-radius: 10px;
    background-color: #F3EFF8;
}

.outcomeCompletedLayout{
    display: grid;
    grid-template-areas:
      'score'
      'outcomeHeader'
      'outcomes'
      'remarksHeader'
      'remarks';
    width:calc(100%-10px);
    grid-gap: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 20px;
    border-radius: 10px;
    background-color: #F3EFF8;
    margin-bottom: 10px;
}

.outcomeLayoutScore{
    grid-area: score;
    font-weight: 700;
    padding-right: 5px;
}

.outcomeLayoutOutcomeHeader{
    grid-area: outcomeHeader;
    font-weight: 700;
}

.outcomeLayoutOutcomes{
    grid-area: outcomes;
}

.outcomeLayoutRemarksHeader{
    grid-area: remarksHeader;
    font-weight: 700;
}

.outcomeLayoutRemarks{
    grid-area: remarks;
    padding-bottom: 10px;
}

.outcomeLayoutFooter{
    grid-area: footer;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 10px;
}

.outcomeInput{
    width: 100%;
    height: 100%;
}

.outcomeOptionLayout{
    display: grid;
    grid-template-areas:
      'checkbox title';
    grid-template-columns: 30px auto;
    grid-gap: 5px;
    width:calc(100% - 10px);
    height: 40px;
    margin-top: 5px;
    margin-left: 10px;
    padding-left: 10px;
    background-color: white;
}

.outcomeOptionLayoutCheckbox{
    grid-area: checkbox;
    display: flex;
    align-items: center;
}

.outcomeOptionLayoutTitle{
    grid-area: title;
    display: flex;
    align-items: center;
}

.noMarginButton{
    margin: 0px;
}

.ReadOnlyTextContainer{
    width: 100%;
    background-color: white;
    border-radius: 5px;
    padding: 5px;
    padding-left: 10px;
    margin-top: 10px;
}

.readOnlyOutcome{
    width: calc(100% - 5px);
}

.scoreText{
    color: var(--branded-purple);
    font-weight: normal;
}

.scoreTextError{
    color: red;
}

.scoreTextErrorDiv{
    color: red;
    font-size: small;
}

.submitLayout{
    display: grid;
    grid-template-areas:
    'description'  
    'loader';
    grid-gap: 10px;
    position: absolute;
    left: 35%;
    top: 40%
}

.submitLayoutDescription{
    grid-area: description;
}

.submitLayoutLoader{
    grid-area: loader;
}

.matrixQuestionTable{
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
}

.matrixQuestionTable thead tr th:nth-of-type(2){
    border-top-left-radius: 10px !important;
}

.matrixQuestionTable thead tr th:last-of-type{
    border-top-right-radius: 10px !important;
}

.matrixQuestionTable tr:first-of-type td:first-of-type{
    border-top-left-radius: 10px !important;
}

.matrixQuestionTable tr:last-of-type td:first-of-type{
    border-bottom-left-radius: 10px !important;
}

.matrixQuestionTable tr:last-of-type td:last-of-type{
    border-bottom-right-radius: 10px !important;
}

.matrixQuestionOptionHeader{
    padding: 10px;
    background-color: var(--lightPurple);
    border: 1px solid #E7E0F1;
    font-weight: 400;
}

.matrixQuestionOptionTextColumn{
    background-color: var(--lightPurple);
    border: 1px solid var(--stroke, #E7E0F1);
    font-weight: 400;
    padding: 10px;
}


.matrixQuestionOptionColumn{
    width: auto;
    border: 1px solid var(--stroke, #E7E0F1)
}

.matrixQuestionOption{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.matrixQuestionOptionRadio{
    margin-bottom: 0px;
}
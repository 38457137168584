.tooltipIcon{
    color: var(--branded-purple);
    margin-top: 10px;
}

.tooltip{
    color: var(--branded-purple);
}

.tooltip :global(.p-tooltip-arrow){
    color: var(--branded-purple);
}

.tooltip :global(.p-tooltip-text){
    background-color: white;
    border-color: var(--branded-purple);
    border: 1px solid;
    color: var(--branded-purple);
}

.tooltipIcon :global(.p-tooltip .p-component .p-tooltip-left .p-tooltip-active){
    background-color: white;
    color: var(--branded-purple);
}
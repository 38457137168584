.contentWrapper {
  display: grid;
  grid-template-columns: 2fr 3fr;
  gap: 30px;
  margin: 0 15px;
  padding: 30px 0;
}

.contentWrapper table {
  min-width: auto;
  font-size: small;
  width: 100%;
}

.title {
  margin-top: 0;
}

.cta {
  margin: 15px 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.cta li {
  display: block;
}

.rsi {
  display: flex;
  margin: 0;
  padding: 15px 0;
  gap: 10px;
}

.rsi li {
  width: 50%;
  padding: 30px;
  display: block;
  background: var(--branded-validation-green);
  text-align: center;
}

.rsi span {
  display: block;
  font-size: 3rem;
}

.participant {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  gap: 10px;
}

.participant li {
  display: block;
}

.summaryTop {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.summaryTop > div {
  display: flex;
  border-bottom: 1px dotted grey;
}

.summaryTop h3 {
  flex-grow: 1;
  margin: 0;
  font-size: medium;
}

.summaryContent {
  display: grid;
  align-items: center;
  grid-template-columns: 200px auto;
  margin: 30px 0 45px;
  gap: 10px;
}

.summaryContent > h2 {
  margin-top: 0;
}

.colLeft {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: lightgrey;
  padding: 10px;
}

.colLeft h4 {
  margin: 0;
}

.colRight {
  font-size: smaller;
}

.submit {
  display: block;
  text-align: center;
  cursor: pointer;
  color: white;
  font-size: 1rem;
  padding: 20px;
  border: none;
  border-radius: 4px;
  background-color: var(--branded-validation-green);
  width: 100%;
  margin-top: 30px;
}
.gridContainer {
    display: grid;
    grid-template-areas:
      'icon main close'
      'icon main closeAndRead';
    width:100%
  }

.gridItemIcon { 
    grid-area: icon; 
    width: 30px;
}

.gridItemMain { 
  grid-area: main;
  width: 300px; 
}

.gridItemClose { 
  grid-area: close;
  width: 30px;
 }

.gridItemCloseAndRead { 
    grid-area: closeAndRead;
    width: 30px;
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end
    
}


  .actionButton {
    min-height: 50px;
    width: 140px;
    align-items: center;
    margin-top: 10px;
  }
.wrapperSm {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapperMed {
  width: 100%;
  min-height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapperFull {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addedOptions {
  width: 100%;
  margin-bottom: 10px;
}

.addOptionButton {
  text-align: right;
}

.fileUploadGroup {
  display: flex;
}

.removeOption {
  height: 42px;
  margin-left: 10px;
  padding: 9px 9px;
}

.cardTitle {
  min-width: 300px;
  height: 50px;
  background-color: var(--branded-purple);
  color: white;
  padding: 13px;
}

.card {
  margin-bottom: 10px;
  margin-right: 15px;
  background-color: ghostwhite;
  position: relative;
  min-width: 360px;
}

.cardOptions {
  text-align: left;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.cardOptionButton {
  width: 40px;
  height: 33px;
  padding: 4px 9px;
  margin-left: 10px;
}

.infoTitle {
  font-weight: 600;
}

.infoTitleGap {
  margin-top: 10px;
}

.infoDetail {
  margin-left: 10px;
  margin-right: 5px;
}

.infoHeader {
  background-color: var(--branded-purple);
}
.documentCardInfoRow {
  padding: 0rem;
  margin-bottom: 1rem;
}

.filterGridContainer {
    display: grid;
    grid-template-areas: 
        'date status organization'
        'search search sort'
        'buttons buttons buttons';
    width: 100%;
    grid-template-columns: 33% 33% 33%;
    grid-gap: 10px;
    font-size: smaller;
}

.filterItemDate {
    grid-area: date
}

.filterItemStatus {
    grid-area: status
}

.filterItemSearch {
    grid-area: search
}

.filterItemSort {
    grid-area: sort
}

.filterItemOrganization {
    grid-area: organization
}

.filterItemButtons {
    grid-area: buttons;
    text-align: right;
    width: 100%;
  }

.label {
    font-weight: bold;
}

.dateInput {
    width: 100%;
}
  
.searchText {
    width: 100%;
}

.helpText {
    font-size: small;
}

.status {
    width: 100%;
}

.calendar{
    width: 100%;
}



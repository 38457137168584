.wrapper {
  background: var(--branded-gray);
  padding: 15px;
}

.wrapper table {
  width: 100%;
  font-size: 75%;
  margin: 10px 0;
}

.wrapper table td {
  overflow-wrap: break-word;
}

.title {
  margin: 0;
  font-size: medium;
}

.fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin: 15px 0;
}

.field {
  display: flex;
  flex-direction: column;
}

.label {
  display: block;
  font-size: 0.875rem;
}

.errFieldMsg {
  font-size: small;
  color: var(--branded-error-red);
}

.buttons {
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-bottom: 15px;
}

.toggle {
  text-decoration: underline;
  background: none;
  border: 0;
  padding: 0;
  font-size: 0.75rem;
  font-weight: 500;
  cursor: pointer;
}

.breadcrumbs {
  font-size: small;
}

.breadcrumbs a {
  text-decoration: underline;
}

.summary {
  padding: 20px 40px;
  border-radius: 5px;
  background-color: var(--branded-gray);
  margin: 30px 0 30px;
}

.summary h2 {
  margin-top: 0;
  padding-bottom: 10px;
  border-bottom: 1px dotted lightgray;
  display: flex;
  align-items: center;
}

.summary h2 svg {
  margin-right: 10px;
}

.details {
  display: grid;
  grid-template-columns: repeat(2, auto);
  margin: 0;
  padding: 0;
  gap: 10px;
}

.details li {
  display: block;
}

.participantName {
  margin-bottom: 15px;
}

.tabs {
  padding: 10px;
}

.tabs h3 {
  margin: 0 0 30px 0;
  display: flex;
  align-items: center;
  padding-left: 15px;
}

.tabs h3 svg {
  margin-right: 5px;
}
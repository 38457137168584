.wrapper {
  margin: 15px 0;
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 15px;
}
.wrapper > div {
  background: white;
}

.sidebar {
  padding: 30px;
}

.sidebar h2 {
  margin-top: 0;
  font-size: 1.2rem;
}

nav ul {
  display: flex;
  gap: 10px;
  flex-direction: column;
  padding: 0;
  margin: 0 0 20px 0;
}

nav ul li {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

li svg {
  margin-right: 5px;
}

.content {
  padding: 30px;
}

.content h2 {
  margin-top: 0;
}

.notifications > div {
  display: grid;
  gap: 10px;
  grid-template-columns: 100px 1fr;
}

.notifications > div p {
  margin-top: 0;
  font-size: 0.8rem;
}

.notifications > div span {
  font-weight: bold;
  font-size: 1.1rem;
  align-self: center;
  justify-self: center;
}

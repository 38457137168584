@import "./AssessmentDefaults.css";

.pageHeader{
    display: grid;
    grid-template-areas:
      'header actions';
    width:100%;
    grid-template-columns: 50% 50% ;
    grid-gap: 5px;
    margin-top: 10px;
}

.pageHeaderText{
    grid-area: header;
}

.pageHeaderActions{
    grid-area: actions;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.pageHeaderActionButton{
    height: 80%;
}

.OverviewLayout{
    display: grid;
    grid-template-areas:
      'header action'
      'summary summary'
      'graph graph'
      'table table' ;
    width:100%;
}

.OverviewLayoutHeader{
    grid-area: header;
    display: flex;
    align-items: center;
    margin-bottom: var(--sectionMarginBottom);
}

.OverviewLayoutAction{
    grid-area: action;
    display: flex;
    justify-content: flex-end;
    align-items: normal;
    width: 100%;
    margin-bottom: var(--sectionMarginBottom);
}

.OverviewLayoutAction :global(.p-dropdown){
    margin-top: 0px;
    height: max-content;
}

.OverviewLayoutSummary{
    grid-area: summary;
    display: flex;
    border: 15px var(--lightPurple) solid;
    gap: 10px;
    border-radius: 10px;
    background-color: var(--lightPurple);
    margin-bottom: var(--sectionMarginBottom);
}

.OverviewLayoutGraph{
    grid-area: graph;
    margin-bottom: var(--sectionMarginBottom);
}

.OverviewLayoutTable{
    grid-area: table;
    margin-bottom: var(--sectionMarginBottom);
}

.overviewSummaryLayout{
    display: grid;
    grid-template-areas:
      'logo description' 
      'logo counter';
    width:100%;
    grid-gap: 1px;
    border-radius: 10px;
    background-color: white;
    height: 100%;
    grid-template-columns: 30px auto;
    padding: 15px; 
}

.overviewSummaryLayoutLogo{
    grid-area: logo;
    display: flex;
    align-items: center;
    padding-right: 5px;
    color: var(--branded-purple);
}

.overviewSummaryLayoutDescription{
    grid-area: description;
    display: flex;
    align-items: flex-end;
}

.overviewSummaryLayoutCounter{
    grid-area: counter;
    display: flex;
    align-items: flex-start;
}

.overviewGraphLayout{
    display: grid;
    grid-template-areas:
      'header period type' 
      'graph graph graph';
    width:100%;
    grid-gap: 10px;
    padding: 10px; 
    grid-template-columns: auto 180px 150px;
}

.overviewGraphLayoutHeader{
    grid-area: header; 
    font-weight: bold; 
    font-size: larger;
    display:  flex;
    align-items: center;
}

.overviewGraphLayoutPeriod
{
    grid-area: period;
}

.overviewGraphLayoutType
{
    grid-area: type;
}

.overviewGraphLayoutGraph
{
    grid-area: graph;
}

.overviewGraphLayoutGraph :global(.p-chart){
    height: 300px;
}

.overviewGraphDropDown{
    width: 100%;
}

.graphHelper{
    font-size: small;
}

.completedLayout{
    display: grid;
    grid-template-areas:
      'header filters sort' 
      'body body body';
      grid-template-columns: auto 200px 310px;
    width:100%;
    grid-gap: 10px;
}

.completedLayoutHeader{
    grid-area: header;
}

.completedLayoutFilters{
    grid-area: filters;
    display: flex;
    justify-content: flex-end;
    margin-bottom: var(--sectionMarginBottom);
}

.completedLayoutSort{
    grid-area: sort;
    display: flex;
    margin-bottom: var(--sectionMarginBottom);
    align-items: center;
}

.completedLayoutSort :global(.buttonContainer){
    margin-top: 0px;
}

.completedLayoutBody{
    grid-area: body;
}

.completedComponent{
    width: 100%;
}

.actionButton{
    background: transparent;
    color: var(--branded-purple);
    border: solid 1px;
    border-radius: 10px;
}

.actionButton :global(.p-button){
    background-color:#DEBEED80 ;
}

.filterButtonText{
    padding-right: 10px;
}

.filterDialog{
    width: 550px;
}

.filterControl{
    width: 100%;
}

.filterLayout{
    display: grid;
    grid-template-areas:
      'createdFrom createdTo' 
      'search search'
      'reset buttons';
    width:100%;
    grid-template-columns: 50% 50% ;
    grid-gap: 10px;
}

.filterLayoutSchedule{
    display: grid;
    grid-template-areas:
      'createdFrom createdFrom createdTo createdTo'
      'search search search search'
      'completed completedInput upcoming upcomingInput' 
      'reset reset buttons buttons';
    width:100%;
    grid-template-columns: 35% 13% 35% 13% ;
    grid-gap: 10px;
}

.filterLayoutFromScheduleCompleted{
    grid-area: completed;
    display: flex;
    align-items: center;
}

.filterLayoutFromScheduleCompletedInput{
    grid-area: completedInput;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.filterLayoutFromScheduleUpcoming{
    grid-area: upcoming;
    display: flex;
    align-items: center;
}

.filterLayoutFromScheduleUpcomingInput{
    grid-area: upcomingInput;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.filterLayoutFrom{
    grid-area: createdFrom;
}

.filterLayoutTo{
    grid-area: createdTo;
}

.filterLayoutSearch{
    grid-area: search;
}

.filterLayoutReset{
    grid-area: reset;
}

.filterLayoutButtons{
    grid-area: buttons;
    display: flex;
    justify-content: flex-end;
}

.blockDialog{
    width: 800px;
}

.blockFooter{
    position: sticky;
    bottom: -15px;
    display: flex;
    justify-content: right;
    width: 100%;
    height: 60px;
    background-color: white;
    padding-top: 10px;
}

.buttonGroup{
    display: flex;
}


.InfoOverlayIconContainer{
    margin-left: 0px;
    margin-right: 10px;
}

.InfoOverlay{
    background-color: #883CAE;
    border: solid black 1px;
}

.InfoOverlayText{
    color: white;
}

.refreshContainer{
    position: relative;
}

.refreshButton{
    margin-left: 10px;
}

.refreshBadge{
    position: absolute;
    top: -10px;
    right: 2px;
}
.cronOption{
    display: flex;
    align-items: center;
}

.cronOptionRadioButton{
    margin-right: 10px;
}

.cronOptionPortion{
    margin-right: 10px;
}

.periodTypeDropDown{
    margin-bottom: 10px;
}

.weeklyContainer{
    display: flex;
    flex-wrap: wrap;
}

.weeklyOption {
    width: 124px;
}

.numberOptionDropDown{
    width: 81px;
}

.nDayOfWeekOptionDropDown{
    width: 116px;
}

.dayOfWeekOptionDropDown{
    width: 146px;
}

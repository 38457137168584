.nestedMultiBlock {
  border-radius: 5px;
  border: 1px solid var(--branded-gray);
  border-left: 8px solid var(--branded-gray);
  padding: 8px 16px;
  margin: 20px 0;
}

.nestedMultiBlock h4 {
  margin: 0 0 20px;
}

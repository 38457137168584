.noteGridContainer {
    display: grid;
    grid-template-areas:
      'author created'
      'referralStatus referralStatus'
      'entityStatus entityStatus'
      'note note';
    grid-template-columns: 60% auto; 
    width:100%;
    border-style: solid;
    border-width: 1px;
    border-color: lightgray;
    padding: 1%;
    margin-bottom: 10px;
}

.noteGridItemAuthor{
    grid-area: author;
    margin-bottom: 5px;
}

.noteGridItemCreated{
    grid-area: created;
    margin-bottom: 5px;
    justify-self: end;
}

.noteGridItemRefStatus{
    grid-area: referralStatus;
    margin-bottom: 5px;
}

.noteGridItemEntityStatus{
    grid-area: entityStatus;
    margin-bottom: 5px;
}

.noteGridItemNote{
    grid-area: note;
    margin-bottom: 5px;
    margin-top: 10px;
}

.noteEntryLabel{
    font-weight: bold;
    margin-right: 10px;
}
.p-menubar.p-component {
  background: none;
  padding: 0;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link * {
  color: rgba(255, 255, 255, 0.8) !important;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
  background:  var(--branded-purple);
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: var(--branded-purple);
  background:var(--branded-purple);
}

.p-accordion-header-text {
  width: 100%
}


.p-scrollpanel .p-scrollpanel-bar {
  background-color:  var(--branded-purple);
  opacity: 1;
  transition: background-color .3s;
}

.p-paginator .p-dropdown{
  margin-top: 0px;
}

.p-paginator .p-dropdown .p-dropdown-label {
  margin-top: -3px;
}

.p-paginator .p-dropdown .p-dropdown-label {
  margin-top: -3px;
}
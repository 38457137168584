
.UserHelpMessage{
    border-radius: 5px;
    background-color: var(--branded-purple);
    color: white;
    padding: 5px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.AddressContainer{
    vertical-align: top;
}

.AddressListContainer{
    width: 20%;
    vertical-align: top;
    display: inline-block;
}

.AddressListContainer .AddressList{
    height: 716px;
}

.AddressListTemaplateItem{
    display: inline-block;
}

.AddressDetail{
    width: 80%;
    vertical-align: top;
    display: inline-block;
}

.AddressDetail .header{
    padding-left: 10px;
}

.AddressFields{
    width: 50%;
    padding-left: 10px;
    display: inline-block;
    vertical-align: top;
}

.MapContainer{
    width: 50%;
    padding-left: 10px;
    display: inline-block;
}

.footer{
    bottom: 0px;
    z-index: 2;
    background-color: var(--branded-gray);
    width: 100%;
    float: right;
}

.footer .buttonContainer{
    text-align: right;
    display: inline-block;
    width: 20%;
}

.button{
    background: var(--branded-purple);
}

.footer .warningMsg{
    text-align:left;
    display: inline-block;
    width: 80%;   
}


.stackedDataTable{
    border: solid;
    border-color: lightgray;
}
.taskGridContainer {
    display: grid;
    grid-template-areas:
      'Type From Info'
      'Participant To Info';
    width:100%;
    grid-template-columns: minmax(200px, 70%) minmax(200px, 30%) auto;
    border-style: solid;
    border-width: 1px;
    border-color: lightgray;
    padding: 1%;
    margin-bottom: 10px;
}

.gridItemType{
    grid-area: Type;
    margin-bottom: 5px;
}

.gridItemFrom{
    grid-area: From;
    margin-bottom: 5px;
    text-align: right;
}

.gridItemTo{
    grid-area: To;
    margin-bottom: 5px;
    text-align: right;
}

.gridItemParticipant{
    grid-area: Participant;
    margin-bottom: 5px;
}

.gridItemInfo{
    grid-area: Info;
    margin-bottom: 5px;
    margin-left: 10px;

}

.gridDescription {
    font-weight: bold;
}

.gridInfoBtn{
    margin-right: 3px;
    width: 40px;
    height: 33px;
    padding: 4px 9px;
}

.gridInfoHeader{
    font-weight: bolder;
    margin-bottom: 10px;
}

.gridInfoHeader2{
    font-weight: bolder;
    margin-bottom: 10px;
    margin-top: 10px;
}

.gridInfoButton{
    float: right;
    margin-top: 10px;
}

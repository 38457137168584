.wrapper {
  display: flex;
  gap: 5px
}

.next {
  display: block;
  cursor: pointer;
  border: 0;
  color: black;
  text-decoration: underline;
  background: none;
  border-radius: 5px;
  padding: 0;
}

button.next:disabled {
  background: none;
  padding: 0;
  border: 0;
}
.save {
  display: block;
  cursor: pointer;
  border: 0;
  color: white;
  background: var(--branded-validation-green);
  border-radius: 5px;
  padding: 5px 15px;
}

.edit {
  display: block;
  cursor: pointer;
  border: 0;
  color: var(--branded-darker-gray);
  background: var(--branded-yellow);
  border-radius: 5px;
  padding: 5px 15px;
}
.wrapper {
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 15px;
}

.sidebar {
  padding: 30px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.sidebar h2 {
  margin-top: 0;
  font-size: 1.2rem;
}

.telerecoveryContent {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.chatMessageBody {
  margin-bottom: 20px;
  width: fit-content;
}

.myMessage,
.staffMessage {
  color: #383838;
  padding: 8px 10px;
  line-height: 12px;
  font-size: 12px;
  text-align: left;
  width: fit-content;
  margin-bottom: 3px;
}

.myMessage {
  background: #E7E0F14D;
  border-radius: 8px;
  border-top-right-radius: 0px;
}

.staffMessage {
  background: #DEBEED80;
  border-radius: 8px;
  border-top-left-radius: 0px;
}

.messageData {
  color: #383838;
  font-size: 12px;
  display: flex;
  gap: 6px;
  width: 100%;
}

.messageDataTime {
  color: #888888;
  margin-left: 15px;
}

.chatHistoryContainer {
  overflow: auto;
  height: 430px;
  padding-right: 10px;
  text-align: center;
  padding-top: 10px;
  margin-top: 10px;
}

.chatHistoryContainer::-webkit-scrollbar {
  width: 10px;
}

.chatHistoryContainer::-webkit-scrollbar-track {
  background-color: white;
}

.chatHistoryContainer::-webkit-scrollbar-thumb {
  background-color: lightgray;
  border-radius: 25px;
}

.newMessageBox {
  border: 1px solid #E7E0F1;
  color: #383838;
  font-size: 12px;
  width: 100%;
}

.sendBtn {
  background-position: center !important;
  background-image: url("../pages/TelerecoveryServices/resources/send.svg") !important;
  background-repeat: no-repeat !important;
  background-color: transparent;
  border: none
}

.sendBtn:hover {
  background-color: transparent !important;
  border: none !;
}

.chatWindowHeader {
  color: #383838;
  padding-bottom: 8px;
  display: flex;
  justify-content: space-between;
  border-bottom: solid 2px;
  border-color: #E7E0F1;
}

.userInitials {
  border-radius: 100px;
  line-height: 32px;
  display: inline-block;
  text-align: center;
  font-size: 10px;
  width: 32px;
  height: 32px;
  background-color: #DEBEED80;
  color: #873CAA;
  margin-right: 3px;
  font-weight: 700;
}

.chatWindowFooter {
  margin-top: 10px;
  display: flex;
  width: 100%;
  gap: 5px;
}

.chatWindowList {
  list-style: none;
  overflow: hidden;
  padding-left: 8px;
}

.convEmptyMsg {
  color: #383838;
  margin-top: 100px;
  display: block;
}

.chatConvMenu {
  background-color: #E7E0F180;
  width: 300px;
  border-radius: 16px;
  padding: 15px;
  height: 600px;
}

.chatConvElement {
  height: 50px;
  display: flex;
  padding: 10px;
  border-radius: 8px;
  width: 100%;
  align-items: center;
  gap: 3px
}

.chatConvElement:hover {
  background: #873CAA66;
}

.chatConvElementSelected {
  background: #E7E0F180;
}

.chatConvElement p {
  text-align: left;
  font-size: 12px;
}

.unread {
  width: 6px;
  height: 6px;
  border-radius: 100px;
  background: #873CAA;
  position: absolute;
  right: 10px;
  top: 22px;
}

.chatWindow {
  padding: 20px;
  background-color: white;
  border-radius: 12px;
}

.mms img {
  border-radius: 8px !important;
  border: 1px solid #E7E0F180;
}

.mms_file_upload {
  height: 36px;
  width: 30px;
  display: flex;
  align-items: center;
  border-radius: 4px;
}

.mms_file_upload_disabled {
  background-color: #cccccca3;
}

.mms_file_upload :global(.p-fileupload-content) {
  display: none !important;
}

.mms_file_upload :global(.p-fileupload-choose) {
  margin: 0 !important;
  width: 24px !important;
  height: 24px !important;
  color: #873caa !important;
  background-color: transparent !important;
  border: none !important;
}

.mms_file_upload :global(.p-fileupload-choose:hover) {
  margin: 0 !important;
  background-color: transparent !important;
  border: none !important;
  color: #873caa !important;
}

.mms_file_upload :global(.p-fileupload-buttonbar) {
  padding-left: 3px !important;
  background-color: transparent !important;
  margin: 0 !important;
  border: none !important;
}

.mms_preview_container {
  margin-top: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}

.mms_preview_container button {
  width: 24px !important;
  height: 24px !important;
  background-color: transparent !important;
  color: #873caa !important;
  border: none !important;
}

.link {
  font-weight: 600;
  font-size: 13px;
  color: #999999;
  text-decoration: underline;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.link:hover {
  cursor: pointer;
}

.buttons {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.button {
  width: fit-content !important;
  border-radius: 28px;
  font-weight: 600;
  font-size: 13px !important;
  min-width: 92px;
}

.cancel_button {
  font-weight: 600;
  font-size: 13px !important;
  width: fit-content !important;
  background-color: transparent;
  padding: 10px 20px;
  border-radius: 28px;
  color: #873caa;
  border-color: #873caa;
  min-width: 92px !important;
  justify-content: center;
}